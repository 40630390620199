<template>
  <div class="box">
    <div class="level">
      <div class="level-left">
        <div>
          <h6 class="title is-6 is-marginless">GPS {{ gps.imei }}</h6>

          <Paragraph
            v-if="gps.autoRenew && !gps.canPurchase"
            class="is-marginless"
            :text="$t('subscription.gps.nextPayment', { date: expiresAt() })">
          </Paragraph>

          <Paragraph
            v-if="!gps.autoRenew && gps.canPurchase"
            class="is-marginless"
            :text="$t('subscription.gps.cancelled', { date: expiresAt() })">
          </Paragraph>
        </div>
      </div>

      <div class="level-right">
        <Button
          v-if="gps.autoRenew"
          :title="$t('profile.account.manage.endSubscription')"
          @click="cancel">
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gps: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    expiresAt () {
      return this.$dayjs(this.gps.expires).format('YYYY-MM-DD')
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
