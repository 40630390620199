<!-- /profile/manage -->
<template>
  <Fragment>
    <div v-if="hasLoadedSubscription">
      <ManageSubscriptionCard v-if="hasSubscription" @cancel="cancalSubscription" />

      <ManageSubscriptionGpsCard
        v-for="device in devices"
        :key="device.imei"
        :gps="device"
        @cancel="cancelGpsSubscription(device)">
      </ManageSubscriptionGpsCard>
    </div>

    <InlineLoader v-else :dark="true" />

    <ManageSubscriptionCancelDialog
      v-if="showCancelDialog"
      :selectedGps="selectedGps"
      @close="closeModal">
    </ManageSubscriptionCancelDialog>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  SUBSCRIPTION_TYPE_PRO,
  SUBSCRIPTION_TYPE_STANDARD,
  SUBSCRIPTION_TYPE_PREMIUM,
  SUBSCRIPTION_TYPE_GPS,
  SUBSCRIPTION_TYPE_GPS_MAX_PREFIX,
  SUBSCRIPTION_STATUS_SUCCESS
} from '@/modules/subscription/store/subscriptionStore'

import ManageSubscriptionCard from './ManageSubscriptionCard.vue'
import ManageSubscriptionGpsCard from './ManageSubscriptionGpsCard.vue'
import ManageSubscriptionCancelDialog from './ManageSubscriptionCancelDialog.vue'

export default {
  components: {
    ManageSubscriptionCard,
    ManageSubscriptionGpsCard,
    ManageSubscriptionCancelDialog
  },

  data () {
    return {
      showCancelDialog: false,
      selectedGps: null
    }
  },

  computed: {
    ...mapGetters({
      subscriptionType: 'subscription/type',
      subscriptionStatus: 'subscription/getStatus'
    }),

    hasLoadedSubscription () {
      return this.subscriptionStatus === SUBSCRIPTION_STATUS_SUCCESS
    },

    hasSubscription () {
      if (!this.subscriptionType) return false

      const subscriptions = [
        SUBSCRIPTION_TYPE_PRO,
        SUBSCRIPTION_TYPE_STANDARD,
        SUBSCRIPTION_TYPE_PREMIUM
      ]

      return subscriptions.includes(this.subscriptionType) || this.subscriptionType.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX)
    },

    hasGps () {
      return this.subscriptionType === SUBSCRIPTION_TYPE_GPS
    },

    devices () {
      const devices = this.$store.getters['subscription/devices'] || []
      return devices.filter(device => this.$dayjs(device.expires).isAfter(new Date()))
    }
  },

  methods: {
    cancalSubscription () {
      this.showCancelDialog = true
    },

    cancelGpsSubscription (gps) {
      this.selectedGps = gps
      this.showCancelDialog = true
    },

    closeModal () {
      this.selectedGps = null
      this.showCancelDialog = false
    }
  }
}
</script>
