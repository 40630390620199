<template>
  <Dialog :title="$t('profile.account.manage.endSubscription')" :buttons="buttons" @close="close">
    <Paragraph :text="$t('profile.account.manage.endSubscriptionHelpText')" />

    <Checkbox
      v-model="survey"
      :disabled="isLoading"
      :label="$t('profile.account.manage.endSubscriptionSurveyText')">
    </Checkbox>

    <div id="smcx-sdk"></div>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { i18n } from '@/i18n'
import { SUBSCRIPTION_TYPE_GPS } from '@/modules/subscription/store/subscriptionStore'

import Dialog from '@/components/ui/dialog2/Dialog2.vue'

export default {
  components: {
    Dialog
  },

  props: {
    selectedGps: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isLoading: false,
      survey: true
    }
  },

  computed: {
    ...mapGetters({
      subscriptionType: 'subscription/type',
      devices: 'subscription/devices'
    }),

    buttons () {
      return [{
        title: this.$t('general.cancel'),
        disabled: this.isLoading,
        click: () => this.close()
      }, {
        title: this.$i18n.t('profile.account.manage.endSubscription'),
        type: 'danger',
        loading: this.isLoading,
        click: this.endSubscription
      }]
    }
  },

  methods: {
    ...mapActions({
      cancelGpsSubscription: 'subscription/cancelGps',
      cancelSubscription: 'subscription/cancel'
    }),

    async endSubscription () {
      this.isLoading = true

      try {
        if (this.subscriptionType === SUBSCRIPTION_TYPE_GPS) {
          await this.cancelGpsSubscription(this.selectedGps.imei)
        } else {
          await this.cancelSubscription()
        }

        this.showSurvey()
        this.close()
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
        this.isLoading = false
      }
    },

    showSurvey () {
      if (this.survey) {
        const url = `/surveys/end-subscription-${(i18n.locale === 'sv' ? 'swedish' : 'english')}.html`
        window.open(url, '_blank', 'height=580, width=580')
      }
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
