<template>
  <div class="box">
    <div class="level">
      <div class="level-left">
        <div>
          <h6 class="title is-6 is-marginless">
            {{ subscriptionTypeName }}
          </h6>

          <p v-if="autoRenew && !canPurchase" class="is-marginless" v-html="$t('subscription.nextPayment', { date: expiresAt() })"></p>
          <p v-if="!autoRenew && canPurchase" class="is-marginless" v-html="$t('subscription.gps.cancelled', { date: expiresAt() })"></p>
        </div>
      </div>

      <div class="level-right">
        <Button
          v-if="autoRenew && isStripe"
          :title="$t('profile.account.manage.endSubscription')"
          @click="cancel">
        </Button>
        <p v-if="isAppStore">{{ $t('subscription.managedInAppStore') }}</p>
        <p v-if="isGooglePlay">{{ $t('subscription.managedInGooglePlay') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { SUBSCRIPTION_TYPE_GPS_MAX_PREFIX } from '@/modules/subscription/store/subscriptionStore'

export default {
  computed: {
    ...mapGetters({
      subscriptionType: 'subscription/type',
      autoRenew: 'subscription/autoRenew',
      canPurchase: 'subscription/canPurchase',
      isStripe: 'subscription/isStripe',
      isAppStore: 'subscription/isAppStore',
      isGooglePlay: 'subscription/isGooglePlay'
    }),

    subscriptionTypeName () {
      if (this.subscriptionType.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX)) {
        const namePrefix = this.$i18n.t('subscription.gps_max.name')

        return namePrefix + ' ' + this.subscriptionType.substring(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX.length)
      }

      return this.$i18n.t(`subscription.${this.subscriptionType}.name`)
    }
  },

  methods: {
    expiresAt () {
      const expires = this.$store.getters['subscription/expires']
      return this.$dayjs(expires).format('YYYY-MM-DD')
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
